import React from 'react'
import PropTypes from 'prop-types'
import { Html } from '@react-three/drei'
import UIHotspot from './UIHotspot'
import * as THREE from 'three'
import useMeasure from 'react-use-measure'

export default function HTMLCanvasHandler({
  hotspotData,
  onClick,
  position,
  scale,
  index,
  bShouldShowActiveHotspot,
  removeActiveHotspot,
}) {
  let v1 = new THREE.Vector3()
  let CalculatePosition = (el, camera, size) => {
    let objectPos = v1.setFromMatrixPosition(el.matrixWorld)
    objectPos.project(camera)
    let widthHalf = size.width / 2
    let heightHalf = size.height / 2
    if (bShouldShowActiveHotspot) {
      // console.log('el', el)
      if (objectPos.x <= -1 || objectPos.x >= 1) {
        removeActiveHotspot()
      }
      // console.log('objectPos of hotspot', objectPos)
    }
    return [objectPos.x * widthHalf + widthHalf, -(objectPos.y * heightHalf) + heightHalf]
  }

  return (
    <Html
      className="HTMLClassfromHandler"
      position={position}
      scale={scale}
      style={{ position: 'relative' }}
      zIndexRange={[1000, 2000]}
      calculatePosition={CalculatePosition}
    >
      <div onClick={onClick} id={index} className="canvasHotspot">
        <UIHotspot
          hotspotData={hotspotData}
          index={index}
          bShouldShowActiveHotspot={bShouldShowActiveHotspot}
        />
      </div>
    </Html>
  )
}

HTMLCanvasHandler.propTypes = {
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  onClick: PropTypes.func,
  position: PropTypes.array,
  scale: PropTypes.array,
  hotspotData: PropTypes.object,
  index: PropTypes.number,
  bShouldShowActiveHotspot: PropTypes.bool,
  removeActiveHotspot: PropTypes.func,
}
