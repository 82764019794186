import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { usePerf } from 'r3f-perf'
import { ARPreview } from '../hero/ARPreview'
import { modelData } from '../../constants'
import UserInteractionGuide from '../hero/UserInteractionGuide'
import QRCode from 'react-qr-code'
import { isIOS } from 'react-device-detect'
import ToggleHotspots from '../hero/ToggleHotspots'

/*
WARNING: Using logEnabled causes the experience to have a memory leak and lag over time, please use it sparingly
*/

export default function Hud({ logEnabled = false, enabled = false }) {
  const { gl, log } = usePerf()
  const [showUserInteractionGuide, setShowUserInteractionGuide] = useState(true)
  const [reso, setReso] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  let androidModelURL = ''
  let iosModelURL = ''
  let pID = 0
  useEffect(() => {
    window.addEventListener('resize', resizing)
    return function cleanup() {
      window.removeEventListener('resize', resizing)
    }
  }, [window])
  function resizing() {
    setReso({ width: window.innerWidth, height: window.innerHeight })
  }

  const params = new URLSearchParams(window.location.search)
  let valueArr = Array.from(params.values())
  let keyArr = Array.from(params.keys())
  if (keyArr.length === 0) {
    androidModelURL = '/GLTF/Winn/Orange/ElectroOrange_V1.gltf'
    iosModelURL = '/GLTF/Winn/Winn_Orange.usdz'
  } else {
    modelData.map((data) => {
      if (parseInt(valueArr[0]) === data.productID) {
        console.log(data)
        pID = data.productID
        androidModelURL = data.androidModelURL
        iosModelURL = data.iosModelURL
      }
    })
  }

  // huig - hide user interaction guide
  function huig() {
    setShowUserInteractionGuide(false)
  }

  return (
    <>
      {enabled && (
        <>
          <div className={css(styles.container)}>
            {showUserInteractionGuide ? <UserInteractionGuide huig={huig} /> : null}
            <ARPreview androidModelURL={androidModelURL} iosModelURL={iosModelURL} id={pID} />
            <ToggleHotspots />
          </div>
        </>
      )}
    </>
  )
}

Hud.propTypes = {
  logEnabled: PropTypes.bool,
  enabled: PropTypes.bool,
}

const styles = StyleSheet.create({
  container: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: '10px',
    // margin: 24,
    padding: 10,
  },
  title: {
    fontSize: 40,
  },
  subtitle: {
    fontSize: 20,
  },
  info: {
    fontSize: 10,
  },
})

{
  /* <div className={css(styles.container)}> */
}
{
  /* <div className={css(styles.title)}>{title}</div> */
}
{
  /* <div className={css(styles.subtitle)}>{version}</div> */
}
{
  /* <div className={css(styles.info)}> */
}
{
  /* <b>Window:</b> {reso.width} x {reso.height} */
}
{
  /* </div> */
}
{
  /* <div className={css(styles.info)}></div> */
}
{
  /* <ARPreview androidModelURL={'/GLTF/Orange/ElectroOrange_V1.gltf'} /> */
}
{
  /* {logEnabled && log != null && gl != null && (
              <>
                <div className={css(styles.info)}>
                  <b>FPS:</b> {log.fps}
                </div>
                <div className={css(styles.info)}>
                  <b>CPU:</b> {log.cpu.toFixed(3)}
                </div>
                <div className={css(styles.info)}>
                  <b>GPU:</b> {log.gpu.toFixed(3)}
                </div>
                <div className={css(styles.info)}>
                  <b>Textures:</b> {gl.memory.textures}
                </div>
                <div className={css(styles.info)}>
                  <b>Calls:</b> {gl.render.calls}
                </div>
              </>
            )} */
}
{
  /* </div> */
}
