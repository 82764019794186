import create from 'zustand'
import staticContent from './static.json'
import devContent from './dev.json'
import { Log, LogError } from './core/CustomLogger'

export const useLandscapeMode = create((set) => ({
  status: false,
  setStatus: (state) => set({ status: state }),
}))

/**
 * useHud
 * For Rendering one element at a time on the Hud, can do multiple if you supply an array and change the logic in Hud.js
 */
export const useHud = create((set) => ({
  requestedFunction: null,
  requestedPopup: null,
  setRequestedPopup: (fn) => set({ requestedPopup: fn }),
  setRequestedFunction: (fn) => set({ requestedFunction: fn }),
}))
export const useStatic = create(() => ({
  baseURL: staticContent.config.baseURL,
  routes: staticContent.routes,
}))
export const useLoader = create((set, get) => ({
  state: { isLoading: false, isDone: false, hasError: false },
  items: devContent.Loader.items,
  doneItems: [],
  doneAmount: 0,
  setDoneAmount: (amount) => set({ doneAmount: amount }),
  hasFinished: () => set({ state: { isLoading: false, isDone: true, hasError: false } }),
  hasError: () => set({ state: { isLoading: false, isDone: true, hasError: true } }),
  startLoading: () => {
    console.time('LoaderTime')
    set({ state: { isLoading: true, isDone: false, hasError: false } })
  },
  itemDoneById: (id) => {
    let state = get().state
    if (state.isLoading) {
      let itemsTemp = get().items
      let item = isIdInItems(itemsTemp, id)
      if (item != null) {
        let doneItemsTemp = get().doneItems
        let alreadyDone = isIdInItems(doneItemsTemp, id)
        if (!alreadyDone) {
          Log('Loader:Item Loaded', item)
          doneItemsTemp.push(item)
          get().setDoneAmount((doneItemsTemp.length / itemsTemp.length) * 100)
          if (doneItemsTemp.length == itemsTemp.length) {
            console.timeEnd('LoaderTime')
            get().hasFinished()
          }
        } else {
          LogError(
            'Loader:Error',
            'This loader "' +
              item.name +
              '" was already done, please check how your code is reporting the load'
          )
          get().hasError()
        }
      } else {
        LogError(
          'Loader:Error',
          'The loader with id: ' +
            id +
            ' was not found in the registered list of loaders, please make sure your loader is present in framework/panorama-viewer/dev.json'
        )
        get().hasError()
      }
    } else {
      LogError('Loader:Not Initialized/ Suspended', 'please call startLoading() to begin tracking')
    }
  },
  itemDoneByName: (name) => {
    console.log(name)
  },
}))

export const usePanoramaTransition = create((set) => ({
  isLoading: false,
  setIsLoading: (state) => set({ isLoading: state }),
}))

function isIdInItems(items, id) {
  for (var item in items) {
    if (items[item].id == id) return items[item]
  }
  return null
}

export const useAutoTour = create((set) => ({
  autoTour: true,
  clicked: false,
  setAutoTour: (bool) => set({ autoTour: bool }),
  setClicked: (bool) => set({ clicked: bool }),
}))
