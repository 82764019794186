import { css, StyleSheet } from 'aphrodite'
import { useHotspot } from '../../store'

const ToggleHotspots = () => {
  const { setShowHotspot } = useHotspot()

  function toggleHotspotsDisplay(e) {
    console.log('e', e, e.target, 'checked', e.target.checked)
    setShowHotspot(e.target.checked)
  }

  return (
    <div className={css(styles['toggle-hotspots'])}>
      <p className={css(styles.hotspotLabel)}>Hotspots</p>
      <label className={css(styles['toggle-switch'])}>
        <input type="checkbox" onClick={toggleHotspotsDisplay} />
        <div className={css(styles.switch)} />
      </label>
    </div>
  )
}

export default ToggleHotspots

const styles = StyleSheet.create({
  'toggle-hotspots': {
    position: 'absolute',
    top: 'clamp(40px, 18vh, 80px)',
    right: '2%',
    pointerEvents: 'auto',
    margin: 5,
    fontWeight: 600,
    '@media only screen and (max-width: 767px) and (orientation: portrait)': {
      top: 'clamp(50px, 18vw, 80px)',
    },
  },
  'toggle-switch': {
    width: 'clamp(35px, 11vh, 50px)',
    height: 'clamp(20px, 6.25vh, 25px)',
    border: '3.5px solid #999',
    borderRadius: 20,
    display: 'block',
    marginLeft: 'auto',
    marginTop: 10,
    ':hover': {
      cursor: 'pointer',
    },
    '@media only screen and (max-width: 767px) and (orientation: portrait)': {
      width: 'clamp(35px, 11vw, 50px)',
      height: 'clamp(20px, 6.25vw, 25px)',
    },
    ':nth-child(n) input': {
      display: 'none',
    },
    ':nth-child(n) input:checked + div': {
      transform: 'translateX(clamp(15px, 5vh ,25px))',
      '@media only screen and (max-width: 767px) and (orientation: portrait)': {
        transform: 'translateX(clamp(15px, 5vw ,25px))',
      },
    },
  },
  switch: {
    width: 'clamp(10px, 3.5vh, 16px)',
    height: 'clamp(10px, 3.5vh, 16px)',
    margin: 1,
    background: '#007fff',
    borderRadius: '50%',
    transition: 'transform .3s',
    '@media only screen and (max-width: 767px) and (orientation: portrait)': {
      width: 'clamp(10px, 3.5vw, 16px)',
      height: 'clamp(10px, 3.5vw, 16px)',
    },
  },
  hotspotLabel: {
    margin: 0,
    fontSize: 'clamp(.65rem, 3vh, 1rem)',
    '@media only screen and (max-width: 767px) and (orientation: portrait)': {
      fontSize: 'clamp(.65rem, 3vw, 1rem)',
    },
  },
})
