export const logEnabled = false

export const modelData = [
  {
    productID: 0,
    androidModelURL: '/GLTF/Winn/Orange/ElectroOrange_V1.gltf',
    iosModelURL: '/GLTF/Winn/Winn_Orange.usdz',
    material: {
      color: '#FF0000',
    },
    transforms: {
      position: [-0.5, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Sturdy Centre Stand',
          description: 'For stable parking',
        },
        position: [0.2, 0.2, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Quick Release Lockable Battery',
          description: 'Easy to remove & Charge',
        },
        position: [-0.25, 1.8, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Aluminum Alloy Step Through Frame',
          description: 'For easy & safer rides',
        },
        position: [1, 2, 0], // frame
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Adjustable Handle Bar',
          description: 'For flexible ergonomics',
        },
        position: [1.4, 3.2, 1], // handle
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Ride Health Display',
          description: 'for battery data, usb charger, riding mode indications',
        },
        position: [1.5, 2.6, 0], // ride health display
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Front Disc Brakes',
          description: 'For better riding control',
        },
        position: [2.5, 1.2, 0.2], // front disc brakes
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Lockable BLDC Motor',
          description: 'Anti theft',
        },
        position: [-1.5, 1, 0.2], // lockable
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Long Trail Carrier',
          description: ' More Storage Space & Payload Capacity',
        },
        position: [-1.5, 2.2, 0], // back carrier
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Additional Front Carrier',
          description: 'For More Storage Capacity',
        },
        position: [2.5, 2.2, 0], // front carrier
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Headlight',
          description: '',
        },
        position: [3.2, 2.2, 0], // headlight
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 1,
    androidModelURL: '/GLTF/Winn/White/ElectroWhite_V1.gltf',
    iosModelURL: '/GLTF/Winn/Winn_White.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [-0.5, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Sturdy Centre Stand',
          description: 'For stable parking',
        },
        position: [0.2, 0.2, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Quick Release Lockable Battery',
          description: 'Easy to remove & Charge',
        },
        position: [-0.25, 1.8, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Aluminum Alloy Step Through Frame',
          description: 'For easy & safer rides',
        },
        position: [1, 2, 0], // frame
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Adjustable Handle Bar',
          description: 'For flexible ergonomics',
        },
        position: [1.4, 3.2, 1], // handle
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Ride Health Display',
          description: 'for battery data, usb charger, riding mode indications',
        },
        position: [1.5, 2.6, 0], // ride health display
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Front Disc Brakes',
          description: 'For better riding control',
        },
        position: [2.5, 1.2, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Lockable BLDC Motor',
          description: 'Anti theft',
        },
        position: [-1.5, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Long Trail Carrier',
          description: ' More Storage Space & Payload Capacity',
        },
        position: [-1.5, 2.2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Additional Front Carrier',
          description: 'For More Storage Capacity',
        },
        position: [2.5, 2.2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Headlight',
          description: '',
        },
        position: [3.2, 2.2, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 2,
    androidModelURL: '/GLTF/C8i/black/C8i_V1.gltf',
    iosModelURL: '/GLTF/C8i/C8i_Black.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Li-ion IP67 water repellent Battery',
          description: '',
        },
        position: [0.5, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 wheel double wall alloy rim',
          description: '',
        },
        position: [-1.4, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '18.5 inch 6061 alloy frame',
          description: '',
        },
        position: [0, 2.25, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 2.85, -0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Integrated Bluetooth',
          description: '',
        },
        position: [1.0, 2.85, 0.4],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Dual Disc Brakes',
          description: '',
        },
        position: [1.5, 1.2, -0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.5, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 speed gears',
          description: '',
        },
        position: [-1.25, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Headlight',
          description: '',
        },
        position: [1.3, 2.24, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 3,
    androidModelURL: '/GLTF/C8i/Red/V1.gltf',
    iosModelURL: '/GLTF/C8i/C8i_Red.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Li-ion IP67 water repellent Battery',
          description: '',
        },
        position: [0.5, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 wheel double wall alloy rim',
          description: '',
        },
        position: [-1.4, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '18.5 inch 6061 alloy frame',
          description: '',
        },
        position: [0, 2.25, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 2.85, -0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Integrated Bluetooth',
          description: '',
        },
        position: [1.0, 2.85, 0.4],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Dual Disc Brakes',
          description: '',
        },
        position: [1.5, 1.2, -0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.5, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 speed gears',
          description: '',
        },
        position: [-1.25, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Headlight',
          description: '',
        },
        position: [1.3, 2.24, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 4,
    androidModelURL: '/GLTF/F6i/Red/F6I_V1.gltf',
    iosModelURL: '/GLTF/F6i/F6i_Red.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Detachable Battery',
          description: '',
        },
        position: [0.5, 1.71, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 wheel double wall alloy rim',
          description: '',
        },
        position: [-1.4, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Rear light',
          description: '',
        },
        position: [-0.85, 2.05, 0.08],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '18.5 inch 6061 alloy frame',
          description: '',
        },
        position: [0, 2.15, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 2.85, -0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Integrated Bluetooth',
          description: '',
        },
        position: [1.0, 2.85, 0.4],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Dual Disc Brakes',
          description: '',
        },
        position: [1.5, 1.2, -0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.5, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 speed gears',
          description: '',
        },
        position: [-1.25, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Headlight',
          description: '',
        },
        position: [1.3, 2.24, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 5,
    androidModelURL: '/GLTF/F6i/Yellow/V1.gltf',
    iosModelURL: '/GLTF/F6i/F6i_Yellow.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Detachable Battery',
          description: '',
        },
        position: [0.5, 1.71, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 wheel double wall alloy rim',
          description: '',
        },
        position: [-1.4, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Rear light',
          description: '',
        },
        position: [-0.85, 2.05, 0.08],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '18.5 inch 6061 alloy frame',
          description: '',
        },
        position: [0, 2.15, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 2.85, -0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Integrated Bluetooth',
          description: '',
        },
        position: [1.0, 2.85, 0.4],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Dual Disc Brakes',
          description: '',
        },
        position: [1.5, 1.2, -0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.5, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 speed gears',
          description: '',
        },
        position: [-1.25, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Headlight',
          description: '',
        },
        position: [1.3, 2.24, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 6,
    androidModelURL: '/GLTF/F3i/Red/F3i_Red.gltf',
    iosModelURL: '/GLTF/F3i/F3i_Red.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Li-ion IP67 water repellent Battery',
          description: '',
        },
        position: [0.5, 1.71, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '29 wheel double wall alloy rim',
          description: '',
        },
        position: [-1.4, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '19 inch MTB Geometry 6061 alloy frame',
          description: '',
        },
        position: [0, 2.15, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '100 mm travel suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 2.85, -0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Integrated Bluetooth',
          description: '',
        },
        position: [1.0, 2.85, 0.4],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Dual Disc Brakes',
          description: '',
        },
        position: [1.5, 1.2, -0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.5, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 speed gears',
          description: '',
        },
        position: [-1.25, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Headlight',
          description: '',
        },
        position: [1.3, 2.24, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 7,
    androidModelURL: '/GLTF/F3i/Black/F3i_Black.gltf',
    iosModelURL: '/GLTF/F3i/F3i_Black.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: 'Li-ion IP67 water repellent Battery',
          description: '',
        },
        position: [0.5, 1.71, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '29 wheel double wall alloy rim',
          description: '',
        },
        position: [-1.4, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '19 inch MTB Geometry 6061 alloy frame',
          description: '',
        },
        position: [0, 2.15, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '100 mm travel suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 2.85, -0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Integrated Bluetooth',
          description: '',
        },
        position: [1.0, 2.85, 0.4],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Dual Disc Brakes',
          description: '',
        },
        position: [1.5, 1.2, -0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.5, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 speed gears',
          description: '',
        },
        position: [-1.25, 1, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Headlight',
          description: '',
        },
        position: [1.3, 2.24, 0],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 8,
    androidModelURL: '/GLTF/C1/C1_Blue.glb',
    iosModelURL: '/GLTF/C1/C1_Blue.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '19 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0, 2.4, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Intube Battery',
          description: '',
        },
        position: [0.05, 1.45, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1.1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Rigid Aerodynamic Fork',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.65, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '700C tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.85, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [0.9, 3.1, 0.8],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1.1, 2.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.9, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.8, 2.25, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 9,
    androidModelURL: '/GLTF/C1/C1_Brown.glb',
    iosModelURL: '/GLTF/C1/C1_Brown.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '19 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0, 2.4, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Intube Battery',
          description: '',
        },
        position: [0.05, 1.45, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1.1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Rigid Aerodynamic Fork',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.65, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '700C tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.85, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [0.9, 3.1, 0.8],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1.1, 2.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.9, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.8, 2.25, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 10,
    androidModelURL: '/GLTF/C1/C1_White.glb',
    iosModelURL: '/GLTF/C1/C1_White.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '19 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0, 2.4, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Intube Battery',
          description: '',
        },
        position: [0.05, 1.45, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1.1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Rigid Aerodynamic Fork',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.65, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '700C tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.85, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [0.9, 3.1, 0.8],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1.1, 2.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.9, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.8, 2.25, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 11,
    androidModelURL: '/GLTF/C5x/C5x_Green.glb',
    iosModelURL: '/GLTF/C5x/C5x_Green.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0.3, 2.5, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP65 5.8Ah Li-ion Detachable Battery',
          description: '',
        },
        position: [0.2, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.8, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80 mm travel suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.6, 1, 0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.7, 0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.1, 0.8],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1, 2.5, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.7, 0.5, 0.4],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 12,
    androidModelURL: '/GLTF/C5x/C5x_Red.glb',
    iosModelURL: '/GLTF/C5x/C5x_Red.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0.3, 2.5, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP65 5.8Ah Li-ion Detachable Battery',
          description: '',
        },
        position: [0.2, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.8, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80 mm travel suspension',
          description: '',
        },
        position: [1, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.6, 1, 0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.7, 0.1],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.1, 0.8],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1, 2.5, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.7, 0.5, 0.4],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 13,
    androidModelURL: '/GLTF/C8x/C8x_Gray.glb',
    iosModelURL: '/GLTF/C8x/C8x_Gray.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0.5, 2.5, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP65 8.7 Ah Li-ion Detachable Battery',
          description: '',
        },
        position: [0.3, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Adjustable Headstem for multiple users',
          description: '',
        },
        position: [0.8, 2.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80 mm travel suspension with lock-in/lock-out',
          description: '',
        },
        position: [1.2, 2, 0.15],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.1, 0.9],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 Speed Gears',
          description: '',
        },
        position: [-1.5, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-0.8, 2.6, -0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.7, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy fire Shimano Shifter',
          description: '',
        },
        position: [1, 3.2, 0.5],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 14,
    androidModelURL: '/GLTF/C8x/C8x_Green.glb',
    iosModelURL: '/GLTF/C8x/C8x_Green.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0.5, 2.5, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP65 8.7 Ah Li-ion Detachable Battery',
          description: '',
        },
        position: [0.3, 2, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Adjustable Headstem for multiple users',
          description: '',
        },
        position: [0.8, 2.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80 mm travel suspension with lock-in/lock-out',
          description: '',
        },
        position: [1.2, 2, 0.15],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.1, 0.9],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 Speed Gears',
          description: '',
        },
        position: [-1.5, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-0.8, 2.6, -0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.7, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy fire Shimano Shifter',
          description: '',
        },
        position: [1, 3.2, 0.5],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 15,
    androidModelURL: '/GLTF/F1/F1_Black.glb',
    iosModelURL: '/GLTF/F1/F1_Black.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0, 2.35, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Detachable Battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.9, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80 mm travel suspension',
          description: '',
        },
        position: [1.2, 2, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.13, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 Speed Gears',
          description: '',
        },
        position: [-1.9, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1.2, 2.7, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.9, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy fire Shimano Shifter',
          description: '',
        },
        position: [1.15, 3.2, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.73, 2.28, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 16,
    androidModelURL: '/GLTF/F1/F1_Red.glb',
    iosModelURL: '/GLTF/F1/F1_Red.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5 Feather light 6061 alloy frame',
          description: '',
        },
        position: [0, 2.35, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Detachable Battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.9, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80 mm travel suspension',
          description: '',
        },
        position: [1.2, 2, 0.2],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.13, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '7 Speed Gears',
          description: '',
        },
        position: [-1.9, 1.3, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Premium Saddle',
          description: '',
        },
        position: [-1.2, 2.7, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid Alloy pedals',
          description: '',
        },
        position: [-0.9, 0.55, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy fire Shimano Shifter',
          description: '',
        },
        position: [1.15, 3.2, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.73, 2.28, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 17,
    androidModelURL: '/GLTF/F6i/F6i_Grey.glb',
    iosModelURL: '/GLTF/F6i/F6i_Grey.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5" (47 cm) 6061 alloy frame',
          description: '',
        },
        position: [0.3, 2.35, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 11.6 Ah detachable battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.6, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '60mm MLO travel suspension',
          description: '',
        },
        position: [1.2, 2.1, 0.25],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1.15, 3.05, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-0.8, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-0.7, 0.55, 0.5],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 18,
    androidModelURL: '/GLTF/F6i/F6i_Orange.glb',
    iosModelURL: '/GLTF/F6i/F6i_Orange.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5" (47 cm) 6061 alloy frame',
          description: '',
        },
        position: [0.3, 2.35, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 11.6 Ah detachable battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.6, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '60mm MLO travel suspension',
          description: '',
        },
        position: [1.2, 2.1, 0.25],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1.15, 3.05, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-0.8, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-0.7, 0.55, 0.5],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 19,
    androidModelURL: '/GLTF/F6i/F6i_White.glb',
    iosModelURL: '/GLTF/F6i/F6i_White.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18.5" (47 cm) 6061 alloy frame',
          description: '',
        },
        position: [0.3, 2.35, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 11.6 Ah detachable battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-1.6, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '60mm MLO travel suspension',
          description: '',
        },
        position: [1.2, 2.1, 0.25],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.7, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.7, 1.8, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1.15, 3.05, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-0.8, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-0.7, 0.55, 0.5],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 20,
    androidModelURL: '/GLTF/C7_Plus/C7_Plus_Grey.glb',
    iosModelURL: '/GLTF/C7_Plus/C7_Plus_Grey.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18" (46 cm) 6061 feather light alloy frame',
          description: '',
        },
        position: [0.3, 2.5, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8/6.36Ah Intube battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80mm travel suspension',
          description: '',
        },
        position: [0.9, 2.1, 0.25],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.4, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.6, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [0.85, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [0.8, 3.05, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-1.25, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-1, 0.5, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.55, 2.15, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 21,
    androidModelURL: '/GLTF/C7_Plus/C7_Plus_Blue.glb',
    iosModelURL: '/GLTF/C7_Plus/C7_Plus_Blue.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18" (46 cm) 6061 feather light alloy frame',
          description: '',
        },
        position: [0.3, 2.5, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8/6.36Ah Intube battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '80mm travel suspension',
          description: '',
        },
        position: [0.9, 2.1, 0.25],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.4, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '27.5 tyre double wall alloy rim',
          description: '',
        },
        position: [1.6, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [0.85, 3.1, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [0.8, 3.05, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-1.25, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-1, 0.5, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.55, 2.15, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 22,
    androidModelURL: '/GLTF/H3/H3_Green.glb',
    iosModelURL: '/GLTF/H3/H3_Green.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18” (46CM) Hi-tensile Carbon Steel frame',
          description: '',
        },
        position: [0.3, 2.5, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Intube Battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'High tensile Steel Rigid Fork',
          description: '',
        },
        position: [1.1, 2.1, 0.15],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.6, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '700C tyre with double wall alloy rim',
          description: '',
        },
        position: [1.6, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 3.2, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.15, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-1.25, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-1, 0.5, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.85, 2.35, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
  {
    productID: 23,
    androidModelURL: '/GLTF/H3/H3_Red.glb',
    iosModelURL: '/GLTF/H3/H3_Red.usdz',
    material: {
      color: '#FFFFFF',
    },
    transforms: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [3, 3, 3],
    },
    hotspots: [
      {
        content: {
          title: '18” (46CM) Hi-tensile Carbon Steel frame',
          description: '',
        },
        position: [0.3, 2.5, 0.0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'IP67 5.8Ah Li-ion Intube Battery',
          description: '',
        },
        position: [0.1, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '250W BLDC Motor',
          description: '',
        },
        position: [-2, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'High tensile Steel Rigid Fork',
          description: '',
        },
        position: [1.1, 2.1, 0.15],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'ISO Certified Dual Disc Brakes',
          description: '',
        },
        position: [1.6, 1, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: '700C tyre with double wall alloy rim',
          description: '',
        },
        position: [1.6, 1.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'LED Display',
          description: '',
        },
        position: [1.1, 3.2, -0.6],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Throttle',
          description: '',
        },
        position: [1, 3.15, 0.85],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Comfortable Sporty Saddle',
          description: '',
        },
        position: [-1.25, 2.6, 0],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Anti-skid pedals',
          description: '',
        },
        position: [-1, 0.5, 0.5],
        scale: [1, 1, 1],
      },
      {
        content: {
          title: 'Easy Access Charging Port',
          description: '',
        },
        position: [0.85, 2.35, -0.1],
        scale: [1, 1, 1],
      },
    ],
  },
]
