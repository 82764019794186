import React, { Fragment, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import { isAndroid, isBrowser, isIOS } from 'react-device-detect'
import QRCode from 'react-qr-code'
import { sendEvent } from '../../helper/google-analytics'

export function ARPreview({ androidModelURL, iosModelURL, id }) {
  const AndroidIntent =
    'intent://arvr.google.com/scene-viewer/1.2?file=' +
    'https://hero-webxr.autovrse.app' +
    androidModelURL +
    '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'

  const IOSIntent = 'https://hero-webxr.autovrse.app' + iosModelURL
  const [showQRCode, setShowQRCode] = useState(false)

  ARPreview.propTypes = {
    androidModelURL: PropTypes.string,
    iosModelURL: PropTypes.string,
    id: PropTypes.number,
  }

  function showQR() {
    setShowQRCode(!showQRCode)
    !showQRCode
      ? sendEvent('opened QR Code', 'AR button', 'QR Code')
      : sendEvent('closed QR Code', 'AR button', 'QR Code')
  }

  return (
    <Fragment>
      <div className={css(styles.ARPreview)}>
        {isBrowser && (
          <button className={css(styles.ARPreviewButton)} onClick={showQR}>
            AR
            {showQRCode ? (
              <div
                style={{
                  backgroundColor: '#414141F3',
                  padding: '2vh',
                  zIndex: 100,
                  marginRight: '-2vw',
                  marginTop: '-2vw',
                }}
              >
                <QRCode
                  className={css(styles.QRCode)}
                  value={
                    'https://ar-redirect.autovrse.app/?android=https://hero-webxr.autovrse.app' +
                    androidModelURL +
                    '&ios=https://hero-webxr.autovrse.app' +
                    iosModelURL
                  }
                ></QRCode>
                <div style={{ paddingTop: '1vh', color: 'white', fontWeight: 'normal' }}>
                  Scan for AR
                </div>
              </div>
            ) : (
              <img src="/images/augmented-reality.png" width={30} alt="AR ICON" />
            )}
          </button>
        )}
        {isAndroid && (
          <button
            className={css(styles.ARPreviewButton)}
            onClick={() => window.open(AndroidIntent)}
          >
            AR
            <img src="/images/augmented-reality.png" width={30} alt="AR ICON" />
          </button>
        )}
        {isIOS && (
          <a rel="ar" className={css(styles.ARPreviewButton)} href={IOSIntent}>
            AR
            <img src="/images/augmented-reality.png" width={30} alt="AR ICON" />
          </a>
        )}
      </div>
    </Fragment>
  )
}

const styles = StyleSheet.create({
  ARPreview: {
    pointerEvents: 'auto',
    position: 'absolute',
    top: '2%',
    right: '2%',
    padding: '5px',
    margin: '5px',
  },
  ARPreviewButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0',
    outline: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: 'clamp(.65rem, 3vh, 1rem)',
    '@media only screen and (max-width: 767px) and (orientation: portrait)': {
      fontSize: 'clamp(.65rem, 3vw, 1rem)',
    },
    ':nth-child(n) img': {
      width: 'clamp(1rem, 7vh, 2rem)',
      marginTop: 5,
      '@media only screen and (max-width: 767px) and (orientation: portrait)': {
        width: 'clamp(1rem, 7vw, 2rem)',
      },
    },
  },
  QRCode: {
    zIndex: 1,
  },
})
