import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import HTMLCanvasHandler from './HTMLCanvasHandler'
import { sendEvent } from '../../helper/google-analytics'

function UIHotspotHandler({ ...props }) {
  const activeHotspot = useRef(null)
  const [bShouldShowActiveHotspot, setShouldShowActiveHotspot] = useState(false)

  //* const selectedHotspots = []  populate this array for multiple selected hotspots

  /**
   * Each hotspot DOM element gets the id as the index and we can use that to iterate over Hotspots
   *
   */

  function onClickEventOutside() {
    if (bShouldShowActiveHotspot) {
      setShouldShowActiveHotspot(!bShouldShowActiveHotspot)
    }
    // console.log('outside hotspot clicked', activeHotspot.current.content.title)
    sendEvent('closed hotspot', 'hotspot', `title: ${activeHotspot.current.content.title}`)
  }

  useEffect(() => {
    if (bShouldShowActiveHotspot) {
      document.addEventListener('click', onClickEventOutside)

      return () => {
        document.removeEventListener('click', onClickEventOutside)
      }
    }
  }, [onClickEventOutside])

  ///! Because of the 'inline-block- property of the hotspot UI, a tiny space was getting added which on click was giving a bug as it does not give us the id of the parent node.
  function onClick(event) {
    if (props.hotspotData[event.target.parentNode.id]) {
      activeHotspot.current = props.hotspotData[event.target.parentNode.id]
      setShouldShowActiveHotspot(!bShouldShowActiveHotspot)

      const activeHotspotToBeSet = props.hotspotData[event.target.parentNode.id]
      sendEvent('opened hotspot', 'hotspot', `title: ${activeHotspotToBeSet.content.title}`)
      // console.log('hotspotData', activeHotspot, activeHotspotToBeSet.content.title)
    }
  }

  function removeActiveHotspot() {
    activeHotspot.current = null
    setShouldShowActiveHotspot(false)
  }

  /**
   *
   * @param {*} hotspotsArrayFromConstant : hotspot array coming from the constants
   * @param {*} incomingArray : hotspot array based on selection, it can either be a single element
   *                             array or multiple hotspots from some sort of selection
   *
   * @returns HTML canvas component that render UI in 3D space based on the array
   */
  //! THE INDEX SENT FOR SINGLE HOTSPOT WILL ALWAYS BE 0 AND NOT THE ACTUAL INDEX IN THE hotspotsArrayFromConstant , SEND IT SOME OTHER WAY.
  function RenderHotSpots(hotspotsArrayFromConstant, incomingArray) {
    let hotspotArray = bShouldShowActiveHotspot === true ? incomingArray : hotspotsArrayFromConstant
    return hotspotArray.map((data, index) => {
      return (
        <HTMLCanvasHandler
          key={index}
          position={data.position}
          scale={data.scale}
          hotspotData={data}
          onClick={onClick}
          index={index}
          bShouldShowActiveHotspot={bShouldShowActiveHotspot}
          removeActiveHotspot={removeActiveHotspot}
        ></HTMLCanvasHandler>
      )
    })
  }

  function RenderSingleHotspot(activeHotspot) {
    return RenderHotSpots(props.hotspotData, [activeHotspot])
  }

  function RenderAllhotSpots() {
    return RenderHotSpots(props.hotspotData)
  }

  return (
    <>
      {bShouldShowActiveHotspot ? RenderSingleHotspot(activeHotspot.current) : RenderAllhotSpots()}
    </>
  )
}

UIHotspotHandler.propTypes = {
  hotspotData: PropTypes.array,
}

export default UIHotspotHandler
