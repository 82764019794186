import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import './Html.css'
import UIHotspotHandler from './UIHotspotHandler'
// import { useLoader } from '../../store'
import LoadBikeModel from '../hero/LoadBikeModel'
import { useHotspot } from '../../store'

export default function ModelLoaderComponent(props) {
  const { showHotspot } = useHotspot()

  return (
    <Suspense fallback={null}>
      <LoadBikeModel {...props} />
      {showHotspot && <UIHotspotHandler hotspotData={props.hotspots} />}
    </Suspense>
  )
}

ModelLoaderComponent.propTypes = {
  hotspots: PropTypes.array,
}
