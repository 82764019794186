import React from 'react'
import { modelData } from '../../constants'
import ModelLoaderComponent from './ModelLoaderComponent'

export default function ModelSelector() {
  const params = new URLSearchParams(window.location.search)
  let keyArr = Array.from(params.keys())
  let valueArr = Array.from(params.values())
  if (keyArr.length === 0) {
    valueArr.push('0')
  }
  return (
    <>
      {modelData.map((data) => {
        if (parseInt(valueArr[0]) === data.productID) {
          return <ModelLoaderComponent {...data} key={data.productID} />
        }
      })}
    </>
  )
}
