import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { useSpring } from '@react-spring/core'
import { a as animated } from '@react-spring/web'

function UIHotspot({ ...props }) {
  const [bRenderTitle, setRenderTitle] = useState(props.bShouldShowActiveHotspot)
  const [bRenderDescription] = useState(true)
  // const [bRenderHotspot, setRenderHotspot] = useState(!props.bShouldShowActiveHotspot)
  const title = props.hotspotData.content.title
  const description = props.hotspotData.content.description

  const fadeHotspotIn = useSpring({
    opacity: props.bShouldShowActiveHotspot ? 1 : 0,
    config: { duration: 250 },
  })

  /**
   *
   * @returns Render Title info of the hotspot. If title will show,
   *          description will show up by deafult but can be changed
   */
  function RenderTitle() {
    return (
      <animated.div className={css(styles.Title)} style={fadeHotspotIn}>
        <h2 className={css(styles.titleHeading)}>{title}</h2>
        {bRenderDescription ? RenderDescription() : null}
      </animated.div>
    )
  }

  /**
   *
   * @returns Renders the hotspot, the rendering is more or less handled in the UIHotspotHandler.
   *          For more control, you can use this too. If Hotspot will show,
   *          title will show up by deafult, but can be changed
   */
  function RenderHotspot() {
    return (
      <>
        <div className={css(styles.UIHotspotClass)}></div>
        {bRenderTitle ? RenderTitle() : null}
      </>
    )
  }

  function RenderDescription() {
    return description && <div className={css(styles.Description)}>{description}</div>
  }

  useEffect(() => {
    setRenderTitle(props.bShouldShowActiveHotspot) //bShouldShowActiveHotspot changes from UIHotspotHandler.
  }, [props.bShouldShowActiveHotspot])

  return <>{RenderHotspot()}</>
}

export default UIHotspot

UIHotspot.propTypes = {
  content: PropTypes.object,
  index: PropTypes.number,
  bShouldShowActiveHotspot: PropTypes.bool,
  hotspotData: PropTypes.object,
}

const styles = StyleSheet.create({
  UIHotspotClass: {
    position: 'relative',
    border: '2px solid black',
    borderRadius: '50%',
    background: 'white',
    padding: 'clamp(5px, 1.5vh, 8px)',
    fontSize: '5px',
    opacity: '1',
    zIndex: 500,
    display: 'inline-block',
    pointerEvents: 'auto',
    cursor: 'pointer',
  },

  Title: {
    zIndex: '2500',
    padding: '5px',
    fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: 'bold',
    maxWidth: '200px',
    borderRadius: '5px',
    border: '1px solid black',
    transformOrigin: '5%',
  },

  Description: {
    flex: '1',
    color: 'black',
    padding: '2px',
    margin: '2px',
    height: 'auto',
    fontStyle: 'normal',
    fontWeight: '400',
    textAlign: 'left',
    fontSize: 'clamp(.5rem, 3vh, 1rem)',
  },
  titleHeading: {
    fontSize: 'clamp(.65rem, 3.5vh, 1.2rem)',
    margin: 4,
  },
})
