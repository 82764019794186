import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useGLTF } from '@react-three/drei'
import './Html.css'
import { useLoader } from '../../store'

export default function LoadBikeModel({ androidModelURL, material, transforms }) {
  const ref = useRef()
  const gltf = useGLTF(androidModelURL)

  // useEffect(() => {
  //   ref.current.children[0].material.color = new Color(material.color)
  // }, [material])

  const { isDone, setIsDone } = useLoader()

  useEffect(() => {
    if (!isDone) {
      // setTimeout(() => {
      setIsDone(true)
      // }, 0)
    }
  }, [gltf])

  console.log('bike model loaded : ', isDone)
  return (
    <group {...transforms}>
      <primitive ref={ref} object={gltf.scene} />
    </group>
  )
}

LoadBikeModel.propTypes = {
  androidModelURL: PropTypes.string,
  material: PropTypes.object,
  transforms: PropTypes.object,
}
