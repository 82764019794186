import { useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { useState } from 'react'
import { useRef } from 'react'

// eslint-disable-next-line react/prop-types
const UserInteractionGuide = ({ huig }) => {
  const [interactionCount, setInteractionCount] = useState(0)
  const interactionCountRef = useRef()
  // using ref for the same value of state with same name because state isn't updating in event listeners without rerending
  interactionCountRef.current = interactionCount

  const clickAnim = useSpring({
    opacity: interactionCount ? 0 : 1,
  })
  // const scrollAnim = useSpring({
  //   opacity: interactionCount === 0 || interactionCount === 2 ? 0 : 1,
  // })

  // console.log('click in comp scope', click)
  useEffect(() => {
    // handlePointDown handles both click in desktop and tap on mobile
    function handlePointerDown() {
      // console.log('interactionCount in point down', interactionCount)
      setInteractionCount(interactionCount + 1)
    }

    // function handleScroll() {
    //   // console.log('interactionCountRef in wheel event', interactionCountRef.current)
    //   if (interactionCountRef.current === 1) {
    //     setInteractionCount((prevState) => prevState + 1)
    //   } else if (interactionCountRef.current === 2) {
    //     setTimeout(() => {
    //       // huig is parent component's (Hud) function which helps in unmounting useIntearactionGuide compononent
    //       huig()
    //     }, 500)

    //     // incremeting interactionCountRef to prevent wheel or pinch event from updating showHotspot state unnecesarily
    //     interactionCountRef.current += 1
    //   }
    // }

    // handlePinch handles pinch event fired with two or more fingers in mobile
    // function handlePinch(e) {
    //   if (e.targetTouches.length == 2 && e.changedTouches.length == 2) {
    //     handleScroll()
    //   }
    // }
    // detecting click on desktop and mobile for fade animation of instruction, hiding drag, swipe & displaying scroll instruction
    window.addEventListener('pointerdown', handlePointerDown)

    // detecting scroll on desktop and hiding drag to rotate instruction
    // window.addEventListener('wheel', handleScroll)

    // event for detecting pinch in mobile
    // window.addEventListener('touchmove', handlePinch)
    // window.addEventListener('touchmove', handlePinch)
    return () => {
      // removing all event listeners after unmounting the component
      // console.log('user interaction component unmounted')
      window.removeEventListener('pointerdown', handlePointerDown)
      // window.removeEventListener('wheel', handleScroll)
      // window.removeEventListener('touchmove', handlePinch)
    }
  }, [])

  return (
    // CSS classnames shortened for use described below
    // inter - interaction, ins - instruction, deskp - desktop, mob - mobile
    <div className="inter-ins">
      <animated.div className="rotate-ins" style={clickAnim}>
        <p className="deskp-ins"> Click and drag to interact</p>
        <p className="mob-ins"> Swipe and Pinch to interact</p>
        <div className="gesture">
          <img src="/images/left-click.png" className="deskp-ins" alt="tap gesture" width={30} />
          <img
            src="/images/tap-gesture.png"
            className="tap-gesture mob-ins"
            alt="tap gesture"
            width={30}
          />
        </div>
      </animated.div>
      {/* <animated.div className="zoom-ins" style={scrollAnim}>
        <p className="deskp-ins"> Scroll to Zoom</p>
        <p className="mob-ins"> Pinch to Zoom</p>
        <div className="gesture">
          <img
            src="/images/scroll-animation.gif"
            className="deskp-ins"
            alt="mouse scroll animation"
            width={30}
          />
          <img
            src="/images/tap-gesture.png"
            className="tap-gesture mob-ins"
            alt="tap gesture"
            width={30}
          />
        </div>
      </animated.div> */}
    </div>
  )
}

export default UserInteractionGuide
