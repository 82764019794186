import './App.css'
import { Canvas } from '@react-three/fiber'
// import Skybox from './core/Skybox'
import SceneLoader from './context/hero/SceneLoader'
import Hud from './context/starter/Hud'
import { Suspense, useState } from 'react'
import { Perf } from 'r3f-perf'
import { logEnabled } from './constants'
import LoadingScreen from '../src/framework/panorama-viewer/core/LoadingScreen'
import { useLoader } from './store'
import Loader from './framework/panorama-viewer/core/Loader'
import LoadingPage from './context/hero/LoadingPage'
import UIElements from './context/hero/UIElements'

// Styleguide - https://github.com/airbnb/javascript - MUST READ
// Vault v1.0
// - Added eslint enforcing
// - Added prettier enforcing
// - Added Panorama Viewer, change the App.js to ./framework/panorama-viewer/App.js
// Contact Admin to enable CI/CD on your branch
// Use Google Chrome & Firefox simultaneously for any testing

function App() {
  return (
    <>
      <Canvas className="canvas" dpr={[1, 1.5]}>
        {logEnabled && <Perf headless />}
        <Suspense fallback={null}>
          <SceneLoader />
        </Suspense>
      </Canvas>
      <UIElements />
    </>
  )
}

export default App
