import React from 'react'
import '../../App.css'
import { useSpring, animated } from 'react-spring'
import { useState } from 'react'
import { useLoader } from '../../store'
import Hud from '../starter/Hud'

function UIElements() {
  const [fadeLoadingPage, setFadeLoadingPage] = useState(false)
  const { isDone } = useLoader()

  const fadeLoadpage = useSpring({
    opacity: isDone ? 0 : 1,
    height: '100%',
    onRest: () => {
      setFadeLoadingPage(true)
    },
  })

  function ShowLoadingPage() {
    return (
      <animated.div className="dual-ring" style={fadeLoadpage}>
        <p className="loadingText">Please wait while we load the bike</p>
      </animated.div>
    )
  }

  function ShowHud() {
    return <Hud enabled={isDone} logEnabled={false} />
  }

  return <>{!fadeLoadingPage ? ShowLoadingPage() : ShowHud()}</>
}

export default UIElements
