import { OrbitControls } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef } from 'react'
import { Vector3 } from 'three'

export default function CameraController() {
  const { camera } = useThree()
  const controls = useRef()

  camera.fov = 30

  useEffect(() => {
    camera.position.set(20, 0, 14)
    controls.current.target = new Vector3(0, 2, 0)
    camera.updateProjectionMatrix()
  }, [])

  return (
    <>
      <OrbitControls
        ref={controls}
        enablePan={false}
        minDistance={0}
        maxDistance={10}
        maxAzimuthAngle={Infinity}
        minAzimuthAngle={Infinity}
        minPolarAngle={-Math.PI / 2}
        maxPolarAngle={Math.PI / 1.9}
        rotateSpeed={0.5}
      />
    </>
  )
}
