import React, { Fragment } from 'react'
import ModelSelector from '../hero/ModelSelector'
import { ContactShadows, Environment } from '@react-three/drei'
import CameraController from '../../core/camera/CameraController'

function SceneLoader() {
  return (
    <Fragment>
      <Environment files="/skybox/sample.hdr" />
      <ambientLight intensity={0} />
      <CameraController />
      <ContactShadows opacity={0.5} scale={10} blur={1} far={10} resolution={256} />
      <ModelSelector />
    </Fragment>
  )
}

export default SceneLoader
